.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.outer {
  /* Set the body height to 100% of the viewport height */
  height: 100vh;
  /* Create two rows: auto for content and 2.298850575vh for the bottom bar */
  display: grid;
  grid-template-rows: auto 2.298850575vh;
  /* Single column layout */
  grid-template-columns: 1fr;
}

/* Container styles */
.container {
  width: 100%;
  /* height: 100%; */
  grid-row: 1 / 2;
  display: block;
  flex-direction: column;
  color: #000000;
  margin-bottom: -2.298850575vh;
}

.logo-header {
  margin-top: 2.298850575vh;
  margin-bottom: 2.298850575vh;
  display: flex;
}

.logo-center {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  align-items: center;
}

.mcdonalds-logo {
  height: 15.517241379vh;
}

.text-logo {
  font-size: 7.327586207vh;
  display: block;
  font-weight: bold;
}

.grid {
  display: grid;
  gap: 0px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding-left: 2.298850575vh;
  padding-right: 2.298850575vh;
  padding-bottom: 2.298850575vh;
}

.menu-item {
  display: block;
}

.text-menu-item {
  display: block;
  font-size: 1.3vh;
  margin-top: 0.3vh;
}

/* Columns wrapper */
.columns-wrapper {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-self: center;
}

.column {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

/* Text styles */
.text {
  margin-bottom: 10px;
  color: #000000;
}

.fill-height {
  height: 100%;
}

/* Image styles */
.pizza-image {
  margin-bottom: 20px;
  max-width: 31.034482759vh;
  max-height: 31.034482759vh;
  width: auto;
  height: auto;
  border-radius: 2.765804598vh;
  margin-top: 1vh;
}

/* Call styles */
.call {
  text-align: left;
  line-height: 2.3vh;
  max-height: 60.5vh;
  overflow: hidden;
  /* margin-top: 3.591954023vh; */
}

.conversationText {
  white-space: pre-wrap;
  color: #4E4E52;
  font-size: 1.795977011vh;
  line-height: 2.909482759vh;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

/* Footer styles */
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

/* Footer item styles */
.footer-item {
  flex: 1;
  text-align: center;
  font-size: 16px;
  margin-top: 2.586206897vh;
  margin-bottom: 2.586206897vh;
}

.footer-item-split {
  border-right: 2px solid #ffffff;
}


/* Footer number styles */
.footer-number {
  font-size: 5.39vh;
}

.button {
  height: 50px;
}

.column1 {
  /* 75% of the container */
  flex: 3;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  text-align: center;
}

.column2 {
  /* 25% of the container */
  flex: 1;
  margin: 0 10px;
  text-align: center;
  margin-left: 2.945402299vh;

  padding-left: 4.454022989vh;
  padding-right: 4.454022989vh;
}

.top-row {
  /* 75% of the container */
  flex: 3;
  display: flex;
  flex-direction: row;
  height: 44.899425286vh;
}

.inner-column1 {
  flex: 1;
  text-align: -webkit-center;
  margin-right: 2.945402299vh;
  margin-bottom: 2.945402299vh;

  padding-bottom: 2.586206897vh;
}

.inner-column2 {
  flex: 1;
  text-align: -webkit-center;
  margin-bottom: 2.945402299vh;
}

.bottom-row {
  /* 25% of the container*/
  flex: 1;
  height: 16.666666667vh;
}

.foreground-container {
  background: white;
  border-radius: 1.077586207vh;
  border: 2px solid #2C2C33;
  /* Note: backdrop-filter has minimal browser support */
  /* backdrop-filter: blur(50px);  this blurs the foreground, don't use it */
}

.heavy-weight {
  font-weight: 700;
}

.light-weight {
  font-weight: 400;
}

.text-dialog {
  font-family: 'Inter';
}

.text-center {
  place-self: 'center';
}

.text-header-size {
  font-size: 3.30vh;
  padding-top: 2.298850575vh;
}

.text-body-size {
  font-size: 2.155vh;
}

.text-win-pizza-size {
  font-size: 2.2vh;
  margin-bottom: 1vh;
}

.text-phone-number-size {
  font-size: 2.87vh;
}

.full-width {
  width: 100%;
}

.block {
  display: block;
}

.topping {
  display: block;
  justify-content: center;
  align-items: center;
}

.topping-image {
  width: 11.002155172vh;
  height: 11.002155172vh;
  border-radius: 1.47341954vh;
}

.toppings-grid {
  display: block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.toppings-grid-row {
  display: flex;
  flex: 1;
  margin-left: 8.800287356vh;
  margin-right: 8.800287356vh;
}

.favorites-text {
  margin-bottom: 1.50862069vh;
}

.topping-1 {
  margin-right: 3.376436782vh;
  margin-bottom: 3.376436782vh;
}

.topping-2 {
  margin-bottom: 3.376436782vh;
}

.topping-3 {
  margin-right: 3.376436782vh;
}

.red-bar {
  height: 0.431034483vh;
  width: 80%;
  background-color: #D90007;
  /* align to bottom of page */
}

.red-bar-custom-width {
  width: 100%;
}

.bottom-yellow-bar {
  /* height: 2.227011494vh; This is now set in `.outer` based on the grid format. */
  grid-row: 2 / 3;
  width: 100%;
  background-color: #FFC300;
}